import {JSONProvider} from "data/providers/json/json.provider";
import {inject, injectable} from "inversify";
import {find, get} from "lodash";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {LocalizationApiProvider} from "data/providers/api/localization.api.provider";
import {Bindings} from "data/constants/bindings";

type TranslationArguments =
	| [key: string, variables?: Record<string, unknown>]
	| [key: string, defaultValue?: string, variables?: Record<string, unknown>];

interface IRequestLocaleParams {
	locale: string;
}

export interface ILocalizationStore {
	get locale(): string | null;

	requestTranslations(params: IRequestLocaleParams): Promise<Record<string, unknown>>;

	requestGameInfo(params: IGetGameInfoQueryVariables): Promise<IGameInfoFragment>;

	switchLocale(params: IRequestLocaleParams): Promise<Record<string, unknown>>;

	t(...args: TranslationArguments): string;

	translate(...args: TranslationArguments): string;

	findValueByLocale(translations: INameTranslationsFragment[]): string;
}

const logMsg = (msg: string) => {
	if (process.env.JEST_WORKER_ID !== undefined) {
		return;
	}

	console.info(msg);
};

const LoggedMessages = new Set<string>();

@injectable()
export class LocalizationStore implements ILocalizationStore {
	@observable private _gameInfo: IGameInfoFragment | null = null;
	@observable private _translations: Record<string, Record<string, unknown>> = {};
	private _pattern = /{{([\w\s]+)}}/g;

	constructor(
		@inject(Bindings.LocalizationApiProvider)
		private _localizationProvider: LocalizationApiProvider,
		@inject(Bindings.JSONProvider) private _jsonProvider: JSONProvider
	) {
		makeAutoObservable(this);
	}

	@observable private _locale: string | null = null;

	get locale() {
		return this._locale;
	}

	@action requestTranslations(params: IRequestLocaleParams): Promise<Record<string, unknown>> {
		return this._jsonProvider.translations(params.locale).then((result) => result.data);
	}

	@action
	async requestGameInfo(params: IGetGameInfoQueryVariables) {
		const result = await this._localizationProvider.gameInfo(params);
		runInAction(() => (this._gameInfo = result.data.game));
		return this._gameInfo!;
	}

	@action
	async switchLocale({locale}: IRequestLocaleParams): Promise<Record<string, unknown>> {
		const hasTranslations = Boolean(this._translations[locale]);

		if (hasTranslations) {
			this._locale = locale;
		}

		const result = await this.requestTranslations({locale});

		runInAction(() => {
			this._translations[locale] = result;

			if (!hasTranslations) {
				this._locale = locale;
			}
		});

		return result;
	}

	findValueByLocale(translations: INameTranslationsFragment[]) {
		return find(translations, ({locale}) => locale === this.locale)?.value ?? "";
	}

	t(...args: TranslationArguments) {
		return this.translate(...args);
	}

	translate(...args: TranslationArguments) {
		const [path, args1, args2] = args;
		const defaultValue = typeof args1 === "string" ? args1 : path;
		const variables = typeof args1 === "object" ? args1 : args2;
		const translationsForLocale = this._translations[this._locale!];

		if (!translationsForLocale) {
			logMsg(`Exception: unexpected locale - ${String(this._locale)} is used`);
		}

		let translationStr = get(translationsForLocale, path);

		if (!translationStr) {
			translationStr = defaultValue;
			const msg = `The translation for the "${path}" path isn't found. Default value "${defaultValue}" used instead`;

			if (!LoggedMessages.has(msg)) {
				LoggedMessages.add(msg);
				logMsg(msg);
			}
		}

		if (typeof translationStr !== "string") {
			throw Error(
				`Exception: the result of ${path} path must be a string, but got ${typeof translationStr}`
			);
		}

		return translationStr.replace(this._pattern, (_, replaceKey: string) =>
			String(get(variables, replaceKey.trim(), ""))
		);
	}
}
