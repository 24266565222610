import {ComponentType} from "react";
import {get, reduce} from "lodash";
import {captureMessage} from "@sentry/react";

type Factory<T> = () => Promise<{
	default: ComponentType<T>;
}>;

export function retryFailLoad<T>(fn: Factory<T>, retriesLeft = 5, interval = 1000): Factory<T> {
	return () =>
		new Promise((resolve, reject) => {
			fn()
				.then(resolve)
				.catch((error: unknown) => {
					trackSentryErrors(error, {}, "retryFailLoad");
					setTimeout(() => {
						if (retriesLeft === 1) {
							reject(error);
							return;
						}

						retryFailLoad(fn, retriesLeft - 1, interval)().then(resolve, reject);
					}, interval);
				});
		});
}

export const copyToClipboard = async (text: string) => {
	if (!navigator?.clipboard) {
		console.warn("Clipboard not supported");
		return false;
	}

	try {
		await navigator.clipboard.writeText(text);
		return true;
	} catch (error) {
		trackSentryErrors(error, {}, "copyToClipboard");
		return false;
	}
};

/**
 * This utility is required to avoid warnings when we're trying to get out of range index from an observable array.
 */
export function getByIndex<T>(array: T[] = [], index: number) {
	const isIndexExist = index >= 0 && array.length > index;
	return isIndexExist ? array[index] : undefined;
}

export const trackSentryErrors = (
	exception: unknown,
	context: Record<string, unknown>,
	contextName: string = "unknown error"
) => {
	const errorName = get(exception, "message", get(exception, "text", contextName)) as string;

	captureMessage(errorName, (scope) => {
		scope.setContext(
			contextName,
			reduce(
				context,
				(acc, value, key) => {
					if (context[key] !== undefined) {
						acc[key] = JSON.stringify(context[key]);
					}

					return acc;
				},
				{} as Record<string, unknown>
			)
		);

		scope.setTag("handler", contextName);
		scope.setTransactionName(contextName);

		return scope;
	});
};
