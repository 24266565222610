import {ModalType} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {first} from "lodash";
import {action, makeAutoObservable, observable} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {GAMEZONE_LINKS_MAPPER} from "data/constants";
import {Bindings} from "data/constants/bindings";

interface IMenuItem {
	url: string;
	label: string;
	children?: {url: string; label: string}[];
}

export interface IHeaderController extends ViewController {
	get isAuthorized(): boolean;

	get rootPath(): string;

	get i18n(): ILocalizationStore;

	get isOpenDrawer(): boolean;

	get userInitials(): string;

	get userFullName(): string;

	get mainMenuList(): IMenuItem[];

	get staticPagesMenuList(): IMenuItem[];

	get guestMenuList(): {
		clickEvent: () => void;
		label: string;
	}[];

	get gameZoneLink(): string;

	toggleDrawer(value: boolean): void;

	openLoginModal(): void;

	openRegistrationModal(): void;
}

@injectable()
export class HeaderController implements IHeaderController {
	@observable public _drawerState: boolean = false;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}

	get rootPath(): string {
		return "/dashboard";
	}

	get userInitials(): string {
		const firstName = first(this._userStore.user?.firstName) || "";
		const lastName = first(this._userStore.user?.lastName) || "";

		return `${firstName}${lastName}`.toUpperCase();
	}

	get userFullName(): string {
		const firstName = this._userStore.user?.firstName || "";
		const lastName = this._userStore.user?.lastName || "";

		return `${firstName} ${lastName}`;
	}

	get isOpenDrawer(): boolean {
		return this._drawerState;
	}

	get mainMenuList() {
		return [
			{
				url: this.rootPath,
				label: this.i18n.t("nav.main.my_picks", "My Picks"),
			},
			{
				url: "leagues",
				label: this.i18n.t("nav.main.leagues", "Leagues"),
				children: [
					{
						url: "leagues/create",
						label: this.i18n.t("nav.main.leagues_create", "Create A League"),
					},
					{
						url: "leagues/join",
						label: this.i18n.t("nav.main.leagues_join", "Join A League"),
					},
				],
			},
			{
				url: "standings",
				label: this.i18n.t("nav.main.standings", "Standings"),
			},
		];
	}

	get guestMenuList() {
		return [
			{
				clickEvent: () => this.openLoginModal(),
				label: this.i18n.t("nav.main.login", "Login"),
			},
			{
				clickEvent: () => this.openRegistrationModal(),
				label: this.i18n.t("nav.main.registration", "Registration"),
			},
		];
	}

	get staticPagesMenuList() {
		return [
			{
				url: "/prizes",
				label: this.i18n.t("nav.main.prizes", "Prizes"),
			},
			{
				url: "/help",
				label: this.i18n.t("nav.main.help", "Help"),
			},
		];
	}

	@action
	toggleDrawer(value: boolean): void {
		this._drawerState = value;
	}

	openLoginModal(): void {
		this._modalsStore.showModal(ModalType.LOGIN);
	}

	openRegistrationModal(): void {
		this._modalsStore.showModal(ModalType.REGISTRATION);
	}

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}

	public get gameZoneLink(): string {
		const locale = (this.i18n.locale ?? "en-US") as keyof typeof GAMEZONE_LINKS_MAPPER;
		return GAMEZONE_LINKS_MAPPER[locale];
	}
}
