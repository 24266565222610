import {ApolloError} from "@apollo/client";
import {RequestState, ModalType} from "data/enums";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, computed} from "mobx";
import {useLocation, resolvePath} from "react-router-dom";
import {Bindings} from "data/constants/bindings";
import {trackSentryErrors} from "data/utils";
import {compact} from "lodash";

interface IControllerProps {
	leagueId: number;
}

interface ITab {
	label: string;
	location: ReturnType<typeof resolvePath>;
}

export interface ILeagueController extends ViewController<IControllerProps> {
	get i18n(): ILocalizationStore;

	get currentLeague(): ILeagueFragment | undefined;

	get currentTabIndex(): number;

	get leagueTabs(): ITab[];

	get currentTab(): ITab;

	setCurrentIndex(tabIndex: number): void;
	setLocation(location: ReturnType<typeof useLocation>): void;
}

@injectable()
export class LeagueController implements ILeagueController {
	@observable private _leagueId!: number;
	@observable private _requestState: RequestState = RequestState.IDLE;
	@observable private _currentLocation!: ReturnType<typeof useLocation>;
	@observable private _currentTabIndex!: number;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) public readonly _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) public readonly _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) public readonly _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@computed
	get currentLeague(): ILeagueFragment | undefined {
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	get currentTabIndex(): ILeagueController["currentTabIndex"] {
		return this._currentTabIndex;
	}

	get currentTab(): ITab {
		return this.leagueTabs[this.currentTabIndex];
	}

	get isCommissioner(): boolean {
		return this._userStore.user?.id === this.currentLeague?.author?.id;
	}

	get isOverallLeague(): boolean {
		return !!this.currentLeague?.type.isOverall;
	}

	get leagueTabs(): ILeagueController["leagueTabs"] {
		if (this._currentLocation) {
			const isCommissioner = this.isCommissioner;
			const pathName = this._currentLocation.pathname;
			const rootPath = resolvePath("..", pathName);

			return compact([
				{
					label: this.i18n.t("league.tab.standings", "Table"),
					location: resolvePath("table", rootPath.pathname),
				},
				this.isOverallLeague
					? null
					: {
							label: this.i18n.t("league.tab.invites", "Invites"),
							location: resolvePath("invites", rootPath.pathname),
					  },
				isCommissioner
					? {
							label: this.i18n.t("league.tab.settings", "Settings"),
							location: resolvePath("about", rootPath.pathname),
					  }
					: {
							label: this.i18n.t("league.tab.about", "About"),
							location: resolvePath("about", rootPath.pathname),
					  },
			]);
		}

		return [];
	}

	setCurrentIndex(tabIndex: number) {
		this._currentTabIndex = tabIndex;
	}

	dispose(): void {
		return;
	}

	setLocation(location: ReturnType<typeof useLocation>) {
		this._currentLocation = location;
		this._currentTabIndex = this.leagueTabs.findIndex(
			(it) => it.location.pathname === this._currentLocation?.pathname
		);
	}

	@action
	async init({leagueId}: IControllerProps) {
		this._leagueId = leagueId;

		const league = this._leaguesStore.getLeagueById(this._leagueId);

		if (!league) {
			try {
				await this._leaguesStore.fetchLeague(leagueId);
			} catch (err) {
				trackSentryErrors(err, {}, "league init");
				this._modalsStore.showModal(ModalType.ERROR, {
					message: (err as ApolloError).message,
				});
			}
		}
	}
}
