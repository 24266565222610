import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import {Modal, Stack, IconButton} from "@mui/material";
import {ModalType} from "data/enums";
import {useViewController} from "data/services/locator/locator_hook.service";
import {isEqual} from "lodash";
import {observer} from "mobx-react";
import React from "react";
import {ButtonPrimary} from "views/components/buttons/buttons.component";
import {
	ModalBox,
	ModalContentCustom,
	ModalIconCloseButtonPosition,
	ModalErrorIcon,
	ModalTitle,
	ModalDescription,
} from "views/components/modals/modal_common.component";
import {IModalsController} from "views/controllers/modals/modals.controller";
import {Bindings} from "data/constants/bindings";

export const ModalLeagueRemoveUser: React.FC = observer(() => {
	const controller = useViewController<IModalsController>(Bindings.ModalsController);
	const {visibleModal, i18n, close, modalContent} = controller;
	const isVisible = isEqual(visibleModal, ModalType.LEAGUE_REMOVE_USER);

	const userName = modalContent?.message || "";
	const onConfirm = () => {
		if (modalContent?.callback) {
			modalContent?.callback();
		}
		close();
	};

	if (!isVisible) {
		return null;
	}

	return (
		<Modal open={isVisible} onClose={close}>
			<ModalBox>
				<ModalContentCustom>
					<ModalIconCloseButtonPosition>
						<IconButton onClick={close}>
							<CloseSharpIcon color={"action"} />
						</IconButton>
					</ModalIconCloseButtonPosition>

					<Stack direction={"column"} alignItems={"center"}>
						<ModalErrorIcon />

						<ModalTitle align="center" variant="h5">
							{i18n.t("modal.league.modal.league.confirm_title", "Are you sure?")}
						</ModalTitle>

						<ModalDescription>
							{i18n.t(
								"modal.league.confirm_title_description",
								`You are removing ${userName}.`,
								{
									X: userName,
								}
							)}
							<br />
							<span>
								{i18n.t(
									"modal.league.confirm_title_description_second",
									"This action cannot be reversed."
								)}
							</span>
						</ModalDescription>

						<Stack spacing={2} direction={"row"}>
							<ButtonPrimary fullWidth variant="outlined" onClick={close}>
								{i18n.t("modal.buttons.close", "Close")}
							</ButtonPrimary>
							<ButtonPrimary fullWidth variant="contained" onClick={onConfirm}>
								{i18n.t("modal.buttons.confirm", "Confirm")}
							</ButtonPrimary>
						</Stack>
					</Stack>
				</ModalContentCustom>
			</ModalBox>
		</Modal>
	);
});
