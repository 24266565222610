import React from "react";
import {Modal} from "@mui/material";
import {isEqual} from "lodash";
import {Exist} from "views/components/exist/exist.component";
import {FormLogin} from "views/components/forms/login/form_login.component";
import {FromRegistration} from "views/components/forms/registration/form_registration.component";
import {useViewController} from "data/services/locator/locator_hook.service";
import {IModalsController} from "views/controllers/modals/modals.controller";
import {ModalType} from "data/enums";
import {observer} from "mobx-react";
import {
	ModalBox as BaseModalBox,
	ModalContent,
} from "views/components/modals/modal_common.component";
import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import {ModalCloseButton} from "views/components/buttons/buttons.component";
import {Bindings} from "data/constants/bindings";
import {getLogoByLocale} from "data/utils/images";

const ModalBox = styled(BaseModalBox)`
	max-width: 600px;
	padding: 48px 0 20px;
`;

const FormWrapper = styled.div`
	width: 100%;
	max-width: 320px;
	margin: 0 auto;
`;

const Logo = styled.img`
	width: 100%;
	max-width: 245px;
	height: auto;
	display: block;
	margin: 0 auto 25px;
`;

const {LOGIN, REGISTRATION} = ModalType;

export const ModalAuth: React.FC = observer(() => {
	const controller = useViewController<IModalsController>(Bindings.ModalsController);
	const {visibleModal, i18n, close} = controller;
	const isVisible = [LOGIN, REGISTRATION].includes(visibleModal!);

	if (!isVisible) {
		return null;
	}

	return (
		<Modal open={isVisible} onClose={close}>
			<ModalBox>
				<ModalCloseButton onClick={close}>
					<CloseIcon />
				</ModalCloseButton>
				<ModalContent>
					<FormWrapper>
						<Logo src={getLogoByLocale(i18n.locale, "black")} role="banner" alt="" />
						<Exist when={isEqual(visibleModal, LOGIN)}>
							<FormLogin />
						</Exist>
						<Exist when={isEqual(visibleModal, REGISTRATION)}>
							<FromRegistration />
						</Exist>
					</FormWrapper>
				</ModalContent>
			</ModalBox>
		</Modal>
	);
});
