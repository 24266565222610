import styled from "@emotion/styled";
import {
	Avatar,
	Divider,
	IconButton,
	ListItemButton,
	ListItemText,
	SwipeableDrawerProps,
	useMediaQuery,
	Box,
	Typography,
} from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {useViewController} from "data/services/locator/locator_hook.service";
import {observer} from "mobx-react";
import React from "react";
import {NavLink} from "react-router-dom";
import {Exist} from "views/components/exist/exist.component";
import {IHeaderController} from "views/components/header/header.controller";
import {IconMenu} from "views/components/icons/icon_menu";
import {Container} from "views/components/structure/structure.component";
import {Bindings} from "data/constants/bindings";
import {getLogoByLocale} from "data/utils/images";
import {IconFB} from "views/components/icons/icon_fb";
import {IconTwitter} from "views/components/icons/icon_twitter";
import {SOCIAL_LINKS} from "data/constants";
const mobileViewQuery = `max-width: 970px`;

const HeaderWrapper = styled.div`
	background-color: var(--primaryColor);
	font-family: var(--fontFamilyBase);
	color: var(--textColor4);
	font-weight: 500;
	font-size: 18px;
	line-height: 20px;
	border-bottom: 4px solid #0a2343;

	@media (${mobileViewQuery}) {
		background-repeat: no-repeat;
		background-position: 50% calc(100% + 4px);
	}
`;

const Wrapper = styled(Container)`
	display: flex;
	justify-content: flex-start;
	height: 68px;

	@media (${mobileViewQuery}) {
		padding: 8px 16px;
		min-height: 82px;
		height: auto;
	}
`;

const GameLogoLink = styled(NavLink)`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 20px;
	@media (${mobileViewQuery}) {
		margin: 0 auto;
	}
`;

const GameLogo = styled.img`
	height: 52px;
	width: auto;
`;

const MenuList = styled.div`
	display: flex;

	button {
		outline: none;
		border: none;
		background: none;
		color: var(--textColor6);
		opacity: 0.9;
	}

	a {
		color: var(--textColor6);
		opacity: 0.9;

		&.active {
			color: var(--textColor4);
			opacity: 1;
		}
	}
`;

const UserInformation = styled(NavLink)`
	margin-left: 20px;
	position: relative;
	cursor: pointer;

	@media (${mobileViewQuery}) {
		margin-left: 0;
	}
`;

const Menu = styled.menu`
	margin-right: auto;
	display: flex;
	flex: 1;
`;

const IconMenuButton = styled(IconButton)`
	height: 60px;
	margin-right: 0;
	padding: 0;

	svg {
		height: 100%;
	}
`;

const BoxWrapper = styled.div`
	width: 240px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	height: 100%;
	flex-direction: column;
`;

const ListItemFull = styled(ListItemButton)`
	width: 100%;
	height: 48px;
	position: relative;

	svg {
		margin-right: 20px;
	}
`;

const ListItemUser = styled(ListItemFull)`
	height: 72px;
	padding-bottom: 16px;
	padding-top: 16px;
`;

const ListFull = styled.div`
	width: 100%;
`;

const RightSide = styled.div`
	display: flex;
	align-items: center;
`;

const Positioned = styled.div`
	position: absolute;
	top: 100%;
	left: 0;
	display: none;
	flex-flow: column;
	background: var(--background3);
	color: var(--textColor7);
	box-shadow: 0 0.1px 0.3px rgba(28, 28, 30, 0.1), 0 1px 2px rgba(28, 28, 30, 0.2);
	border-radius: 0 0 4px 4px;
	width: max-content;
	z-index: 1;

	a {
		white-space: nowrap;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		opacity: 1;
		padding: 8px 12px;
		color: var(--textColor7);

		&:last-child {
			border: none;
		}

		&:hover {
			color: var(--textColor7);
		}

		&.active {
			color: var(--textColor7);
		}
	}

	& > div {
		border-bottom: 1px solid #cccccc;

		&:last-child {
			border-bottom: none;
		}
	}
`;

const RelativeBox = styled(Box)`
	position: relative;
	margin-left: 32px;
	display: flex;
	align-items: center;

	a {
		white-space: nowrap;
	}

	&:hover {
		.sub-menu {
			display: flex;
		}
	}
`;

const Submenu = styled.div`
	padding-left: 20px;
`;

const Follow = styled.div`
	color: var(--textColor4);
	display: flex;
	align-items: center;
	gap: 12px;

	svg {
		transition: all 0.5s;
		width: 22px;
		height: 22px;
	}

	a:hover svg {
		opacity: 0.7;
	}
`;

const LinkSpan = styled(Typography)`
	line-height: 32px;
	margin-left: 20px;
`;
/**
 * Override type casting because of outdated versions of React
 */
const Drawer = SwipeableDrawer as unknown as React.FC<SwipeableDrawerProps>;

export const Header: React.FC = observer(() => {
	const isMobileView = useMediaQuery(`(${mobileViewQuery})`);
	const controller = useViewController<IHeaderController>(Bindings.HeaderController);
	const {i18n, staticPagesMenuList} = controller;
	const showDrawer = () => controller.toggleDrawer(true);
	const hideDrawer = () => controller.toggleDrawer(false);
	const links = SOCIAL_LINKS[i18n.locale || ""] || SOCIAL_LINKS["en-us"];

	return (
		<HeaderWrapper>
			<Wrapper>
				<Exist when={isMobileView}>
					<IconMenuButton
						color="inherit"
						aria-label="open drawer"
						onClick={showDrawer}
						edge="end">
						<IconMenu />
					</IconMenuButton>
					<Drawer
						anchor={"left"}
						open={controller.isOpenDrawer}
						onClose={hideDrawer}
						onOpen={showDrawer}
						BackdropProps={{
							sx: {
								background: "transparent",
							},
						}}
						PaperProps={{
							sx: {
								color: "#FFF",
								background: "var(--background2)",
							},
						}}>
						<BoxWrapper>
							<ListFull>
								<Exist when={controller.isAuthorized}>
									<NavLink to="/my-account">
										<ListItemUser>
											<Avatar
												sx={{
													backgroundColor: "var(--textColor5)",
													color: "#FFF",
												}}>
												{controller.userInitials}
											</Avatar>
											<ListItemText sx={{marginLeft: "15px"}}>
												{controller.userFullName}
											</ListItemText>
										</ListItemUser>
									</NavLink>
									<Divider sx={{borderColor: "var(--background4)"}} />
								</Exist>
								<Exist when={controller.isAuthorized}>
									{controller.mainMenuList.map((item, index) => (
										<div key={index}>
											<ListItemButton
												component={NavLink}
												to={item.url}
												onClick={hideDrawer}>
												<LinkSpan variant={"menuLinks"}>
													{item.label}
												</LinkSpan>
											</ListItemButton>
											<Exist when={item.children}>
												<Submenu>
													{item.children?.map((subMenuItem) => (
														<ListItemButton
															component={NavLink}
															to={subMenuItem.url}
															key={subMenuItem.url}
															onClick={hideDrawer}>
															<LinkSpan variant={"menuLinks"}>
																{subMenuItem.label}
															</LinkSpan>
														</ListItemButton>
													))}
												</Submenu>
											</Exist>
										</div>
									))}
								</Exist>
								<Exist when={!controller.isAuthorized}>
									<ListItemButton component={NavLink} to="/" onClick={hideDrawer}>
										<LinkSpan variant={"menuLinks"}>
											{i18n.t("nav.main.home", "Home")}
										</LinkSpan>
									</ListItemButton>
									{controller.guestMenuList.map((item, index) => (
										<ListItemButton
											key={index}
											onClick={() => {
												item.clickEvent?.();
												hideDrawer();
											}}>
											<LinkSpan variant={"menuLinks"}>{item.label}</LinkSpan>
										</ListItemButton>
									))}
								</Exist>
								<Divider sx={{borderColor: "var(--background4)"}} />
								{staticPagesMenuList.map(({label, url}, index) => (
									<ListItemButton
										key={index}
										component={NavLink}
										to={url}
										onClick={hideDrawer}>
										<LinkSpan variant={"menuLinks"}>{label}</LinkSpan>
									</ListItemButton>
								))}
								<Exist when={controller.isAuthorized}>
									<ListItemButton
										component={NavLink}
										to="/my-account"
										onClick={hideDrawer}>
										<LinkSpan variant={"menuLinks"}>
											{i18n.t("nav.main.my_account", "My Account")}
										</LinkSpan>
									</ListItemButton>
								</Exist>
							</ListFull>
						</BoxWrapper>
					</Drawer>
				</Exist>
				<GameLogoLink to={controller.rootPath}>
					<GameLogo src={getLogoByLocale(i18n.locale)} alt="Game Zone logo" />
				</GameLogoLink>
				<Exist when={!isMobileView}>
					<Menu>
						<MenuList>
							{controller.isAuthorized
								? controller.mainMenuList.map((item, index) => (
										<RelativeBox key={index}>
											<NavLink to={item.url}>
												<Typography variant={"menuLinks"}>
													{item.label}
												</Typography>
											</NavLink>
											<Exist when={item.children}>
												<Positioned className={"sub-menu"}>
													{item.children?.map((it, index) => (
														<ListItemButton
															component={NavLink}
															to={it.url}
															key={index}>
															<Typography
																variant={"menuLinks"}
																sx={{fontSize: "14px"}}>
																{it.label}
															</Typography>
														</ListItemButton>
													))}
												</Positioned>
											</Exist>
										</RelativeBox>
								  ))
								: controller.guestMenuList.map((item, index) => (
										<RelativeBox key={index}>
											<button onClick={item.clickEvent}>
												<Typography variant={"menuLinks"}>
													{item.label}
												</Typography>
											</button>
										</RelativeBox>
								  ))}
							{staticPagesMenuList.map(({label, url}, index) => (
								<RelativeBox key={index}>
									<NavLink to={url}>
										<Typography variant={"menuLinks"}>{label}</Typography>
									</NavLink>
								</RelativeBox>
							))}
						</MenuList>
					</Menu>
				</Exist>
				<RightSide>
					<Exist when={!isMobileView && !controller.isAuthorized}>
						<Follow>
							<Typography variant={"body1Medium"}>
								{i18n.t("footer.follow_mac", "Follow the MAC")}
							</Typography>
							<a href={links.facebook} target="_blank" rel="noopener noreferrer">
								<IconFB />
							</a>
							<a href={links.twitter} target="_blank" rel="noopener noreferrer">
								<IconTwitter />
							</a>
						</Follow>
					</Exist>
					<Exist when={controller.isAuthorized}>
						<UserInformation to="my-account">
							<Avatar
								sx={{
									background: "#F7F7F7",
									color: "var(--textColor5)",
								}}>
								{controller.userInitials}
							</Avatar>
						</UserInformation>
					</Exist>
				</RightSide>
			</Wrapper>
		</HeaderWrapper>
	);
});
