import en_logo_black from "assets/img/game_logo_black_en.png";
import en_logo_white from "assets/img/game_logo_white_en.png";

const BLACK_LOGO = {
	en: en_logo_black,
};

const WHITE_LOGO = {
	en: en_logo_white,
};

export const getLogoByLocale = (locale: string | null, type: "black" | "white" = "white") => {
	const logoSet = type === "white" ? WHITE_LOGO : BLACK_LOGO;
	return logoSet.en;
};
